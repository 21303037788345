.header {
  --logo-max-width: 553px;
  padding: 1rem 0 0;
  background: var(--color-gradient-dark-to-light);
  display: grid;
  grid-template-columns: min(100vw, var(--size-max-width));
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
  grid-template-areas:
          "head"
          "navi"
          "part";
  &__headline {
    grid-area: head;
    display: inline-flex;
    justify-content: center;
    img {
      max-width: var(--logo-max-width);
      width: 100%;
    }
  }
  &__partners {
    max-width: calc(550px + 2rem);
    padding: 0 1rem;
    width: 100%;
    grid-area: part;
    margin: 0 auto;
    //filter: drop-shadow(0 0 40px rgba(0,0,0,.2));
  }

  &__nav {
    grid-area: navi;
    ul {
      display: grid;
      gap: 1rem;
      grid-template-columns: 1fr 1fr;
      margin: 1rem 0;
      padding: 0 1rem;

      li {
        background: var(--color-background-light);
        padding: .25rem 1rem;
        border-radius: var(--dimension-radius-sm);
        text-align: center;
        transition: 150ms ease-in-out;
        &:hover,
        &:active,
        &:focus {
          transform: scale(1.1);
        }
        a {
          font-family: var(--font-serif);
          font-size: var(--font-medium-size);
          line-height: var(--font-medium-line-height);
          color: var(--color-primary);
          text-decoration: none;
          text-transform: capitalize;
          white-space: nowrap;
        }
      }
    }
  }

  @media screen and (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
          "head head"
          "part navi";
    &__partners {
      padding: 2rem .5rem 0;
    }
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(2, var(--size-max-fr));
    grid-template-areas:
          "part head"
          "part navi";
    align-items: end;
    &__partners {
      padding: 35% 1rem 0;
    }
    &__nav {
      align-self: start;
      justify-self: center;
      ul {
        grid-template-columns: repeat(4, 1fr);
        padding: 0 1rem 0;
        max-width: var(--logo-max-width);
        background: linear-gradient(transparent 0px, transparent calc(50% - 1px), var(--color-primary) calc(50% - 1px), var(--color-primary) calc(50% + 1px), transparent calc(50% + 1px), transparent 100%);
      }
    }
  }
}

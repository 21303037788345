language-offer {
  &:empty {
    display: none;
  }
  position: fixed;
  bottom: 0;
  right: 0;
  display: grid;
  padding: 1rem;
  background: var(--color-background-light);
  z-index: 99999;

  $blur: 40px;
  @supports (backdrop-filter: blur($blur)) {
    backdrop-filter: blur($blur);
    background: rgba(255,255,255, 0.7);
  }

  @supports (-webkit-backdrop-filter: blur($blur)) {
    -webkit-backdrop-filter: blur($blur);
    background: rgba(255,255,255, 0.7);
  }
  display: grid;
  gap: 1rem;

  &.language-selector--close {
    transition: 250ms ease-in-out;
    transform: translate3d(0, 100%, 0);
  }

  grid-template-areas:
          "p p"
          "s j";
  p {
    grid-area: p;
    margin: 0;
    padding: 0;
  }
  .language-selector__button-stay,
  .language-selector__button-jump {
    padding: .5rem 1rem;
    text-align: center;
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      background: var(--color-background-light);
    }
  }
  .language-selector__button-jump {
    grid-area: j;
    display: block;
    background: var(--color-background);
    color: var(--color-text);
    text-decoration: none;
    box-shadow: 0 0 1px rgba(0,0,0,0.5);
  }

  .language-selector__button-stay {
    grid-area: s;
    border: none;
    background: none;
    padding: .5rem 1rem;
    text-align: center;

  }
}

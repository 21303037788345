html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button, input, select {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

img, video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

:root {
  --color-background: #e2e7ee;
  --color-background-light: #fff;
  --color-gradient-blue: #b3c4dc;
  --color-text: #000;
  --color-primary: #003e69;
  --color-step-1: #396b8d;
  --color-step-2: #5787a9;
  --color-step-3: #94bad6;
  --color-gradient-dark-to-light: linear-gradient(180deg, var(--color-gradient-blue) 0, var(--color-background) 100%);
  --color-gradient-light-to-dark: linear-gradient(180deg, var(--color-background) 0, var(--color-gradient-blue) 100%);
  --color-gradient-stripes-dark-to-light: linear-gradient(var(--color-step-1) 0%, var(--color-step-1) 33%, var(--color-step-2) 33%, var(--color-step-2) 66%, var(--color-step-3) 66%, var(--color-step-3) 100%);
  --color-gradient-stripes-light-to-dark: linear-gradient(var(--color-step-3) 0%, var(--color-step-3) 33%, var(--color-step-2) 33%, var(--color-step-2) 66%, var(--color-step-1) 66%, var(--color-step-1) 100%);
  --color-gradient-stripes-ending: linear-gradient(-1deg var(--color-step-1) 180px, var(--color-step-2) 181px, var(--color-step-2) 100%);
  --font-serif: Cinzel, serif;
  --font-sans: Arial, Helvetica, sans-serif;
  --font-medium-size: 1rem;
  --font-medium-line-height: 1.325rem;
  --size-max-width: 1240px;
  --size-max-width-text: 762px;
  --size-max-fr: calc(min(var(--size-max-width), 100vw) / 2 - 4rem);
  --dimension-radius-sm: 2px;
  --shadow-default: 0 2px 6px rgba(0, 0, 0, .25);
}

html, body {
  width: 100%;
  height: 100%;
  background: var(--color-background);
  overflow-x: hidden;
}

body {
  font-size: 16px;
  font-family: var(--font-sans);
  color: var(--color-text);
  text-rendering: geometricprecision;
  font-weight: 500;
  line-height: 1.32rem;
}

header, main, footer, section {
  width: 100%;
  float: left;
  transform: translate3d(0, 0, 0);
}

h1, h2, h3 {
  font-family: var(--font-serif);
}

h2 {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2.64rem;
}

@media screen and (min-width: 400px) {
  h2 {
    font-size: 2rem;
  }
}

p {
  margin: 1rem 0;
}

p:last-child {
  margin-bottom: 0;
}

main ul {
  padding-left: 1rem;
}

main ul li {
  margin-bottom: .5rem;
  list-style: disc;
  display: list-item;
}

main ul li:last-child {
  margin-bottom: 0;
}

.card {
  box-shadow: var(--shadow-default), inset 0 2px 5px #fff;
  background: #f2f2f2;
  padding: 2rem;
}

hr {
  height: 1px;
  width: 100%;
  box-shadow: none;
  background: #000;
  border: none;
  margin: 1rem 0;
  display: block;
}

.skewed, .contact, #nieruchomosci, #property, #fundacje, #foundations, #przedsiebiorcy, #enterpreneurs, #sport, .about {
  position: relative;
}

.skewed:before, .contact:before, #nieruchomosci:before, #property:before, #fundacje:before, #foundations:before, #przedsiebiorcy:before, #enterpreneurs:before, #sport:before, .about:before {
  content: "";
  box-shadow: var(--shadow-default), inset var(--shadow-default);
  z-index: 0;
  position: absolute;
  inset: -30px;
  transform: rotateZ(-1deg);
}

.skewed > *, .contact > *, #nieruchomosci > *, #property > *, #fundacje > *, #foundations > *, #przedsiebiorcy > *, #enterpreneurs > *, #sport > *, .about > * {
  z-index: 1;
  position: relative;
}

.about {
  justify-content: center;
  align-items: center;
  padding: 4rem 1rem;
  display: grid;
}

.about:before {
  background: var(--color-gradient-stripes-dark-to-light);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
}

.about .card {
  width: 100%;
  max-width: var(--size-max-width-text);
}

.layout-two-col {
  min-height: max(80vh, 800px);
  background-repeat: no-repeat;
  background-size: cover;
  grid-template-columns: 1fr;
  gap: 2rem;
  padding: 4rem 1rem;
  display: grid;
}

@media screen and (min-width: 850px) {
  .layout-two-col {
    grid-template-columns: repeat(2, var(--size-max-fr));
    justify-content: center;
    align-items: center;
  }
}

.layout-one-card__content {
  padding: 4rem 1rem;
}

#sport:before {
  background-image: url("basket-small.ea9ca977.webp");
}

@media screen and (min-width: 501px) {
  #sport:before {
    background-image: url("basket.659dbe1c.webp");
  }
}

#przedsiebiorcy:before, #enterpreneurs:before {
  background-image: url("office-small.c225c9ea.webp");
}

@media screen and (min-width: 501px) {
  #przedsiebiorcy:before, #enterpreneurs:before {
    background-image: url("office.acd797a4.webp");
  }
}

#fundacje:before, #foundations:before {
  background-image: url("conference-small.f206408c.webp");
}

@media screen and (min-width: 501px) {
  #fundacje:before, #foundations:before {
    background-image: url("conference.9821e6db.webp");
  }
}

#nieruchomosci:before, #property:before {
  background-image: url("housing-small.acd69798.webp");
}

@media screen and (min-width: 501px) {
  #nieruchomosci:before, #property:before {
    background-image: url("housing.d43940e0.webp");
  }
}

.partners {
  background: var(--color-step-1);
  margin-top: 7rem;
  padding: 7rem 1rem 8rem;
  position: relative;
}

@media screen and (min-width: 600px) {
  .partners {
    padding: 20rem 2rem 8rem;
  }
}

.partners:before {
  content: "";
  background: var(--color-gradient-stripes-light-to-dark);
  height: 32rem;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  z-index: 0;
  display: block;
  position: absolute;
  top: -6rem;
  left: -30px;
  right: -30px;
  transform: rotateZ(-1deg);
  box-shadow: inset 0 4px 4px -2px rgba(0, 0, 0, .25);
}

.partners__content {
  z-index: 1;
  max-width: var(--size-max-width);
  margin: 0 auto;
  position: relative;
}

.partners__content h3 {
  font-family: var(--font-sans);
  margin: 1em 0;
  font-size: 2rem;
  line-height: 1em;
}

.partners__content__items {
  grid-template-columns: 1fr;
  gap: 4rem;
  display: grid;
}

.partners__content__items__item {
  flex-direction: column;
  display: flex;
  position: relative;
}

.partners__content__items__item img {
  border-radius: var(--dimension-radius-sm);
  box-shadow: 0 0 50px rgba(0, 0, 0, .25);
}

.partners__content__items__item__text {
  z-index: 10;
  background: linear-gradient(#f2f2f2, rgba(0, 0, 0, 0));
  padding: 1rem;
  position: relative;
}

@media screen and (min-width: 800px) {
  .partners__content__items {
    grid-template-columns: 1fr 1fr;
  }
}

.contact {
  min-height: 400px;
  gap: 4rem;
  margin: 1rem 0;
  padding: 0 1rem;
  display: grid;
}

.contact:before {
  background: var(--color-step-2);
}

.contact__address {
  font-style: normal;
}

.contact__map {
  cursor: pointer;
  position: relative;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .25);
}

.contact__map__image {
  display: flex;
}

.contact__map__image img {
  border-radius: var(--dimension-radius-sm);
}

.contact__map__buttons {
  opacity: 0;
  border-radius: var(--dimension-radius-sm);
  pointer-events: none;
  background: rgba(255, 255, 255, .7);
  justify-content: center;
  align-items: center;
  padding: 2rem;
  display: grid;
  position: absolute;
  inset: 0;
}

@supports ((-webkit-backdrop-filter: blur(26px)) or (backdrop-filter: blur(26px))) {
  .contact__map__buttons {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: rgba(255, 246, 128, .1);
  }
}

@supports ((-webkit-backdrop-filter: blur(26px))) {
  .contact__map__buttons {
    -webkit-backdrop-filter: blur(20px);
    background: rgba(255, 246, 128, .1);
  }
}

.contact__map__buttons ul {
  gap: 1rem;
  display: grid;
}

.contact__map__buttons ul li {
  list-style: none;
  display: block;
}

.contact__map__buttons ul li a {
  color: var(--color-text);
  background: var(--color-background-light);
  width: 100%;
  text-align: center;
  border-radius: var(--dimension-radius-sm);
  padding: .5rem 1rem;
  text-decoration: none;
  transition: all .15s ease-in-out;
  display: inline-block;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .25), inset 0 2px 5px #fff;
}

.contact__map__buttons ul li a:hover, .contact__map__buttons ul li a:active, .contact__map__buttons ul li a:focus {
  transform: scale(1.1);
}

.contact__map--clicked .contact__map__buttons {
  opacity: 1;
  pointer-events: all;
}

.language-select {
  position: absolute;
  top: -2px;
  right: 1rem;
}

.header {
  --logo-max-width: 553px;
  background: var(--color-gradient-dark-to-light);
  grid-template-columns: min(100vw, var(--size-max-width));
  width: 100%;
  grid-template-areas: "head"
                       "navi"
                       "part";
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0 0;
  display: grid;
}

.header__headline {
  grid-area: head;
  justify-content: center;
  display: inline-flex;
}

.header__headline img {
  max-width: var(--logo-max-width);
  width: 100%;
}

.header__partners {
  max-width: calc(550px + 2rem);
  width: 100%;
  grid-area: part;
  margin: 0 auto;
  padding: 0 1rem;
}

.header__nav {
  grid-area: navi;
}

.header__nav ul {
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin: 1rem 0;
  padding: 0 1rem;
  display: grid;
}

.header__nav ul li {
  background: var(--color-background-light);
  border-radius: var(--dimension-radius-sm);
  text-align: center;
  padding: .25rem 1rem;
  transition: all .15s ease-in-out;
}

.header__nav ul li:hover, .header__nav ul li:active, .header__nav ul li:focus {
  transform: scale(1.1);
}

.header__nav ul li a {
  font-family: var(--font-serif);
  font-size: var(--font-medium-size);
  line-height: var(--font-medium-line-height);
  color: var(--color-primary);
  text-transform: capitalize;
  white-space: nowrap;
  text-decoration: none;
}

@media screen and (min-width: 800px) {
  .header {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "head head"
                         "part navi";
  }

  .header__partners {
    padding: 2rem .5rem 0;
  }
}

@media screen and (min-width: 1200px) {
  .header {
    grid-template-columns: repeat(2, var(--size-max-fr));
    grid-template-areas: "part head"
                         "part navi";
    align-items: end;
  }

  .header__partners {
    padding: 35% 1rem 0;
  }

  .header__nav {
    place-self: start center;
  }

  .header__nav ul {
    max-width: var(--logo-max-width);
    background: linear-gradient(transparent 0px, transparent calc(50% - 1px), var(--color-primary) calc(50% - 1px), var(--color-primary) calc(50% + 1px), transparent calc(50% + 1px), transparent 100%);
    grid-template-columns: repeat(4, 1fr);
    padding: 0 1rem;
  }
}

language-offer {
  background: var(--color-background-light);
  z-index: 99999;
  grid-template-areas: "p p"
                       "s j";
  gap: 1rem;
  padding: 1rem;
  display: grid;
  position: fixed;
  bottom: 0;
  right: 0;
}

language-offer:empty {
  display: none;
}

@supports ((-webkit-backdrop-filter: blur(40px)) or (backdrop-filter: blur(40px))) {
  language-offer {
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    background: rgba(255, 255, 255, .7);
  }
}

@supports ((-webkit-backdrop-filter: blur(40px))) {
  language-offer {
    -webkit-backdrop-filter: blur(40px);
    background: rgba(255, 255, 255, .7);
  }
}

language-offer.language-selector--close {
  transition: all .25s ease-in-out;
  transform: translate3d(0, 100%, 0);
}

language-offer p {
  grid-area: p;
  margin: 0;
  padding: 0;
}

language-offer .language-selector__button-stay, language-offer .language-selector__button-jump {
  text-align: center;
  cursor: pointer;
  padding: .5rem 1rem;
}

language-offer .language-selector__button-stay:hover, language-offer .language-selector__button-stay:active, language-offer .language-selector__button-stay:focus, language-offer .language-selector__button-jump:hover, language-offer .language-selector__button-jump:active, language-offer .language-selector__button-jump:focus {
  background: var(--color-background-light);
}

language-offer .language-selector__button-jump {
  background: var(--color-background);
  color: var(--color-text);
  grid-area: j;
  text-decoration: none;
  display: block;
  box-shadow: 0 0 1px rgba(0, 0, 0, .5);
}

language-offer .language-selector__button-stay {
  text-align: center;
  background: none;
  border: none;
  grid-area: s;
  padding: .5rem 1rem;
}

footer {
  background: var(--color-gradient-light-to-dark);
  justify-content: center;
  align-items: center;
  padding: 6rem 0;
  display: grid;
}

footer img {
  max-width: min(430px, 75vw);
  width: 100%;
}

/*# sourceMappingURL=en.75adffb4.css.map */

html, body {
  width: 100%;
  height: 100%;
  background: var(--color-background);
  overflow-x: hidden;
}

body {
  font-size: 16px;
  font-family: var(--font-sans);
  color: var(--color-text);
  font-weight: 500;
  text-rendering: geometricPrecision;
  line-height: 1.32rem;

}

header, main, footer, section {
  width: 100%;
  float: left;
  transform: translate3d(0,0,0);
}

h1, h2, h3 {
  font-family: var(--font-serif);
}

h2 {
  font-size: 1.8rem;
  line-height: 2.64rem;
  font-weight: 300;

  @media screen and (min-width: 400px) {
    font-size: 2rem;
  }
}

p {
  margin: 1rem 0;
  &:last-child {
    margin-bottom: 0;
  }
}

main {
  ul {
    padding-left: 1rem;
    li {
      display: list-item;
      list-style: disc;
      margin-bottom: .5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


.card {
  background: #f2f2f2;
  box-shadow: var(--shadow-default), inset 0 2px 5px rgba(255,255,255,1);
  padding: 2rem;
}

hr {
  display: block;
  height: 1px;
  background: black;
  width: 100%;
  border: none;
  box-shadow: none;
  margin: 1rem 0;
}

.skewed {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    inset: -30px;
    transform: rotateZ(-1deg);
    box-shadow: var(--shadow-default), inset var(--shadow-default);
    z-index: 0;
  }

  & > * {
    position: relative;
    z-index: 1;
  }
}

.about {
  @extend .skewed;
  padding: 4rem 1rem;
  display: grid;
  align-items: center;
  justify-content: center;
  &:before {
    background: var(--color-gradient-stripes-dark-to-light);
    backdrop-filter: blur(1px);
  }

  .card {
    width: 100%;
    max-width: var(--size-max-width-text);
  }
}

.layout-two-col {
  padding: 4rem 1rem;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: max(80vh, 800px);
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media screen and (min-width: 850px) {
    grid-template-columns: repeat(2, var(--size-max-fr));
    align-items: center;
    justify-content: center;
  }
}

.layout-one-card {
  &__content {
    padding: 4rem 1rem;
  }
}

@mixin background($name) {
  &:before {
    background-image: url("../../assets/backgrounds/#{$name}-small.webp");
    @media screen and (min-width: 501px) {
      background-image: url("../../assets/backgrounds/#{$name}.webp");
    }
  }
}
#sport {
  @extend .skewed;
  @include background("basket");
}

#przedsiebiorcy,
#enterpreneurs {
  @extend .skewed;
  @include background("office");
}

#fundacje,
#foundations {
  @extend .skewed;
  @include background("conference");
}

#nieruchomosci,
#property {
  @extend .skewed;
  @include background("housing");
}

.partners {
  position: relative;
  background: var(--color-step-1);
  padding: 7rem 1rem 8rem;
  margin-top: 7rem;
  @media screen and (min-width: 600px) {
    padding: 20rem 2rem 8rem;
  }

  &:before {
    content: "";
    background: var(--color-gradient-stripes-light-to-dark);
    display: block;
    height: 32rem;
    position: absolute;
    top: -6rem;
    left: -30px;
    right: -30px;
    box-shadow: inset 0 4px 4px -2px rgba(0,0,0,0.25);
    backdrop-filter: blur(1px);
    transform: rotateZ(-1deg);
    z-index: 0;
  }

  &__content {
    position: relative;
    z-index: 1;
    max-width: var(--size-max-width);
    margin: 0 auto;
    h3 {
      font-family: var(--font-sans);
      font-size: 2rem;
      line-height: 1em;
      margin: 1em 0;
    }
    &__items {
      display: grid;
      grid-template-columns: 1fr;
      gap: 4rem;

      &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        img {
          box-shadow: 0 0 50px rgba(0,0,0,0.25);
          border-radius: var(--dimension-radius-sm);
        }
        &__text {
          position: relative;
          z-index: 10;
          padding: 1rem;
          background: linear-gradient(#f2f2f2, transparent);
        }
      }

      @media screen and (min-width: 800px) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}


.contact {
  @extend .skewed;
  &:before {
    background: var(--color-step-2);
  }
  padding: 0 1rem;
  margin: 1rem 0;
  display: grid;
  gap: 4rem;
  min-height: 400px;

  &__address {
    font-style: normal;
  }

  &__map {
    position: relative;
    box-shadow: 0 2px 6px rgba(0,0,0,0.25);
    cursor: pointer;

    &__image {
      display: flex;

      img {
        border-radius: var(--dimension-radius-sm);
      }
    }
    &__buttons {
      position: absolute;
      inset: 0;
      align-items: center;
      justify-content: center;
      background: rgba(255,255,255,0.7);
      padding: 2rem;
      display: grid;
      opacity: 0;
      border-radius: var(--dimension-radius-sm);
      pointer-events: none;
      @supports (backdrop-filter: blur(26px)) {
        background: rgba(255, 246, 128, 0.1);
        backdrop-filter: blur(20px);
      }

      @supports (-webkit-backdrop-filter: blur(26px)) {
        background: rgba(255, 246, 128, 0.1);
        -webkit-backdrop-filter: blur(20px);
      }

      ul {
        display: grid;
        gap: 1rem;
        li {
          display: block;
          list-style: none;
          a {
            color: var(--color-text);
            padding: .5rem 1rem;
            background: var(--color-background-light);
            display: inline-block;
            width: 100%;
            text-decoration: none;
            text-align: center;
            border-radius: var(--dimension-radius-sm);
            box-shadow: 0 2px 6px rgba(0,0,0,0.25), inset 0 2px 5px rgba(255,255,255,1);
            transition: 150ms ease-in-out;
            &:hover,
            &:active,
            &:focus {
              transform: scale(1.1);
            }
          }
        }
      }
    }

  
    &--clicked {
      .contact__map__buttons {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}

.language-select {
  position: absolute;
  top: -2px;
  right: 1rem;
}


:root {
  --color-background: #E2E7EE;
  --color-background-light: #ffffff;
  --color-gradient-blue: #B3C4DC;
  --color-text: #000000;
  --color-primary: #003E69;
  --color-step-1: #396B8D;
  --color-step-2: #5787A9;
  --color-step-3: #94BAD6;
  --color-gradient-dark-to-light: linear-gradient(180deg, var(--color-gradient-blue) 0, var(--color-background) 100%);
  --color-gradient-light-to-dark: linear-gradient(180deg, var(--color-background) 0, var(--color-gradient-blue) 100%);
  --color-gradient-stripes-dark-to-light: linear-gradient(var(--color-step-1) 0%, var(--color-step-1) 33%, var(--color-step-2) 33%, var(--color-step-2) 66%, var(--color-step-3) 66%, var(--color-step-3) 100%);
  --color-gradient-stripes-light-to-dark: linear-gradient(var(--color-step-3) 0%, var(--color-step-3) 33%, var(--color-step-2) 33%, var(--color-step-2) 66%, var(--color-step-1) 66%, var(--color-step-1) 100%);
  --color-gradient-stripes-ending: linear-gradient(-1deg var(--color-step-1) 180px, var(--color-step-2) 181px, var(--color-step-2) 100%);
  --font-serif: Cinzel, serif;
  --font-sans: Arial, Helvetica, sans-serif;

  --font-medium-size: 1rem;
  --font-medium-line-height: 1.325rem;

  --size-max-width: 1240px;
  --size-max-width-text: 762px;
  --size-max-fr: calc(min(var(--size-max-width), 100vw) / 2 - 4rem);
  --dimension-radius-sm: 2px;
  --shadow-default: 0 2px 6px rgba(0,0,0,0.25);
}

footer {
  background: var(--color-gradient-light-to-dark);
  display: grid;
  padding: 6rem 0;
  justify-content: center;
  align-items: center;

  img {
    max-width: min(430px, 75vw);
    width: 100%;
  }
}
